$(document).on('turbolinks:load', async () => {
    await navigator.serviceWorker.register('/serviceworker.js')
});

$(document).on('click', '#webpush-button', async () => {
  if (navigator.serviceWorker != undefined) {
    const sw = await navigator.serviceWorker.ready;
    await sw.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: window.vapidPublicKey
    })
  }

  if (!("Notification" in window)) {
    alert("This browser does not support desktop notification");
  }

  // Let's check whether notification permissions have already been granted
  else if (Notification.permission === "granted") {
  // If it's okay let's create a notification
    var notification = new Notification("Hi there!");
  }

  // Otherwise, we need to ask the user for permission
  else if (Notification.permission !== 'denied') {
    Notification.requestPermission(function (permission) {

      // Whatever the user answers, we make sure we store the information
      if(!('permission' in Notification)) {
        Notification.permission = permission;
      }

      // If the user is okay, let's create a notification
      if (permission === "granted") {
        var notification = new Notification("Hi there!");
      }
    });
  } else {
    alert(`Permission is ${Notification.permission}`);
  }
});