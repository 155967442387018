$(document).on("mouseover", "#sidebar-wrapper", function(e) {
    e.preventDefault();
    $("#wrapper").removeClass("toggled");
    $(".sidebar_overlay").addClass("showed");
});
$(document).on("mouseleave", "#sidebar-wrapper", function(e) {
    e.preventDefault();
    $("#wrapper").addClass("toggled");
    $(".sidebar_overlay").removeClass("showed");
});
$(document).on("click", ".list-group-item", function() {

    $(".list-group-item").each(function() {
        $(this).removeClass("active");
    });
    $(this).addClass("active");

});

$(document).on("click", ".search_icon img", function() {
    $(this).closest(".search_wrapper").toggleClass("collapsed");
});

$(document).on('click', '.filter-button-group button', function() {
    let filterValue = $(this).attr('data-filter');
    if (filterValue === '*') {
        $('#grid_isotope .grid-item').each(function() {
            $(this).css('display', 'block');
        });
    } else {
        $('#grid_isotope .grid-item').each(function() {
            if ($(this).hasClass(filterValue)) {
                $(this).css('display', 'block');
            } else {
                $(this).css('display', 'none');
            }
        });
    }
    $('.filter-button-group button').each(function() {
        $(this).removeClass('active');
    });
    $(this).addClass('active');
});

$(document).on('click', '#docCompleteConfirmModal .custom_checkbox input[type=checkbox]', function() {
    let submitLinkElem = $('#docCompleteConfirmModal .modal_footer a.link_submit');

    if (this.checked)
        submitLinkElem.removeClass('disabled');
    else
        submitLinkElem.addClass('disabled');
});

function toggleSelectionSection(index) {
    if (index === 0) {
        $('.selection_section').removeClass('show');
    } else {
        $('.selection_section').addClass('show');
        $('.selected_results span').text(index);
    }
}

function updateSuperDataTableSelectAllCtrl(table){
    var $table             = table.table().node();
    var chkbox_select_all  = $('thead input[name="select_all"]', $table).get(0);

    if($chkbox_checked.length === 0){
        chkbox_select_all.checked = false;
    } else if ($chkbox_checked.length === $chkbox_all.length){
        chkbox_select_all.checked = true;
    } else {
        chkbox_select_all.checked = true;
    }
    toggleSelectionSection($chkbox_checked.length);

}

var table, activityTable, missedElementsTable;
document.addEventListener("turbolinks:before-cache", function() {
    if (table !== null) {
        table.destroy();
        table = null
    }

    if (activityTable !== null) {
        activityTable.destroy();
        activityTable = null
    }

    if (missedElementsTable !== null) {
        missedElementsTable.destroy();
        missedElementsTable = null
    }
});

$(document).on('turbolinks:load', function (){
    activityTable = $('#recent_activity_table').DataTable({
        "pageLength": 6,
    });
    missedElementsTable = $('#missed_elements_table').DataTable({
        "pageLength": 6,
        'columnDefs': [{
            'targets': 0,
            'orderable': true
        }]
    });


    var rows_selected = [];
    var rowSelectedIds = [];
    table = $('#employee_table').DataTable({
        "pageLength": 10,
        "pagingType": "full_numbers",
        'columnDefs': [{
            'targets': 0,
            'searchable': false,
            'orderable': false,
            'width': '1%',
            'className': 'dt-body-center',
            'render': function (){
                return '<label class="custom_checkbox">\n' +
                       '<input type="checkbox">\n' +
                       '<span class="custom_check"></span>\n' +
                       '</label>';
            }
        }],
        'order': [[1, 'asc']],
        'rowCallback': function(row, data){
            var rowId = data[0];

            if($.inArray(rowId, rows_selected) !== -1){
                $(row).find('input[type="checkbox"]').prop('checked', true);
                $(row).addClass('selected');
            }
        }
    });


    $('.datatable_section #search').keyup(function(){
        table.search($(this).val()).draw();
        showPositions();
    });
    $('.datatable_section .position_filter').on('click', function(){
        table.search($(this).attr('data-filter')).draw();
        showPositions();
    });
    $('.datatable_section .all').on('click', function(){
        table.search('').draw();
        showPositions();
    });
    function showPositions() {
        $('#employee_table tbody tr').each(function() {
            let parent = $(this).find('td:nth-child(4)');
            if ($(this).find('td:nth-child(4) .positionWrapper').length >= 2) {
                if (parent.find('.other_options')) {
                    parent.find('.other_options').empty();
                }
                $(this).find('td:nth-child(4) .positionWrapper').each(function(index) {
                    if (index >= 2) {
                        $(this).addClass('hidden');
                    }
                });
                if ($(this).find('td:nth-child(4) .positionWrapper').length >= 1 && ($(this).find('td:nth-child(4) .positionWrapper').length - 2) > 0 ) {
                    parent.append('<span class="other_options">+ '+ ($(this).find('td:nth-child(4) .positionWrapper').length - 2) +' other positions</span>');
                }
            }
        });
        
        $("#employee_table .dataTables_empty").text($(".no_matching_records").text())
    }

    $('#employee_table tbody').on('click', 'input[type="checkbox"]', function(e){
        var $row = $(this).closest('tr');
        var data = table.row($row).data();
        var rowId = data[0];
        var rowIndex = table.row($row).index();
        var index = $.inArray(rowId, rows_selected);
        if(this.checked && index === -1){
            rows_selected.push(rowId);
            rowSelectedIds.push(rowIndex);
        } else if (!this.checked && index !== -1){
            rows_selected.splice(index, 1);
            rowSelectedIds.splice(index, 1);
        }
        if(this.checked){
            $row.addClass('selected');
        } else {
            $row.removeClass('selected');
        }

        // updateDataTableSelectAllCtrl(table);
        e.stopPropagation();
    });

    $('thead input[name="select_all"]', table.table().container()).on('click', function(e){
        if(this.checked){
            $('#employee_table tbody input[type="checkbox"]:not(:checked)').trigger('click');
        } else {
            $('#employee_table tbody input[type="checkbox"]:checked').trigger('click');
        }
        e.stopPropagation();
    });
    $('#employee_table_previous').html("<");
    $('#employee_table_next').html(">");
    table.on('draw', function(){
        // updateDataTableSelectAllCtrl(table);
        $('#employee_table_previous').html("<");
        $('#employee_table_next').html(">");
    });

    $('.selection_menu ul li').on('click', function () {
        $(this).closest('.selection_menu ul.assign__selection').removeClass('active');
        $(this).closest('.selection_menu').find('.selection_menu_item').removeClass('changed');
        $(this).closest('.selection_menu').find('.selection_menu_item span').text($(this).text());
        let assignType = $(this).closest('.selection_menu ul.assign__selection').attr('value');
        let assignIndex = $(this).attr('value');

        data = {
            a_type: assignType,
            a_ids: rows_selected,
            a_index: assignIndex
        };
        updateAssigning(assignType, data, table, rowSelectedIds);

    });
    $('.btn_del_multi_employee').on('click', function() {
        data = {
            d_type: 'delete',
            a_ids: rows_selected
        };

        deleteMultiElements(data, table);
    });

    super_table = $('#super_table').DataTable({
        "pageLength": 10,
        "pagingType": "full_numbers",
        'columnDefs': [{
            'targets': 0,
            'searchable': false,
            // 'orderable': false,
            // 'width': '1%',
            // 'className': 'dt-body-center'
            
        }],
        'order': [[1, 'asc']],
        'rowCallback': function(row, data){
            var rowId = data[0];
        }
    });

    $('#super_table_previous').html("<");
    $('#super_table_next').html(">");
    super_table.on('draw', function(){
        $('#super_table_previous').html("<");
        $('#super_table_next').html(">");
    });

    $(".dataTables_empty").text($(".no_table_text").text())

});

function updateAssigning(assignType, data, table, rowSelectedIds) {
    let token = $('meta[name="csrf-token"]').attr('content');
    $.ajax({
        url: '/employees/assigning',
        type: 'post',
        dataType: "json",
        beforeSend: function(xhr) {
            xhr.setRequestHeader('X-CSRF-Token', token)
        },
        data: data,
        success: function(response) {
            updateDataTableDatas(table, response, rowSelectedIds);
        },
        error: function(error) {
            console.log(error);
        }
    });
}

function updateDataTableDatas(table, res, rowSelectedIds) {
    for (i = 0; i < res['data']['employees'].length; ++i) {
        for (j = 0; j < table.rows().count(); j++) {
            let data = table.row(j).data();

            if (parseInt(data[0]) === parseInt(res['data']['employees'][i]['id'])) {
                var newData = '';
                if (res['data']['employees'][i]['positions'].length === 0) {
                    newData += '';
                } else if (res['data']['employees'][i]['positions'].length === 1) {
                    newData =
                        '<img src="/assets/icon/'+ res['data']['employees'][i]['positions'][0]['icon'] +'">\n' +
                        '<span>'+ res['data']['employees'][i]['positions'][0]['name'] +'</span>\n';
                } else if (res['data']['employees'][i]['positions'].length === 2) {
                    for (k = 0; k < res['data']['employees'][i]['positions'].length; k++) {
                        newData +=
                            '<img src="/assets/icon/'+ res['data']['employees'][i]['positions'][k]['icon'] +'">\n' +
                            '<span>'+ res['data']['employees'][i]['positions'][k]['name'] +',</span>\n';
                    }

                } else if (res['data']['employees'][i]['positions'].length > 2) {
                    for (k = 0; k < 2; k++) {
                        newData +=
                            '<img src="/assets/icon/'+ res['data']['employees'][i]['positions'][k]['icon'] +'">\n' +
                            '<span>'+ res['data']['employees'][i]['positions'][k]['name'] +',</span>\n';
                    }
                    if (res['data']['employees'][i]['positions'].length >= 1 && (res['data']['employees'][i]['positions'].length - 2) > 0) {
                        newData += '<span class="other_options">+ '+ (res['data']['employees'][i]['positions'].length - 2) +' other positions</span>';
                    }

                }
                updatedData = [data[0], data[1], data[2], newData ,data[4]];
                table.row(j).data(updatedData).draw();
            }
        }
    }
    for (i = 0; i < res['data']['positions'].length; ++i) {
        $('.filter_btn_wrapper').find('.position_filter').each(function() {
            if (parseInt($(this).attr('data-id')) === parseInt(res['data']['positions'][i]['id'])) {
                $(this).html(res['data']['positions'][i]['name'] + ' (' + res['data']['positions'][i]['users'] + ')');
            }
        });
    }
}

function deleteMultiElements(data, table) {
    let token = $('meta[name="csrf-token"]').attr('content');
    $.ajax({
        url: '/employees/destroy',
        type: 'post',
        dataType: "json",
        beforeSend: function(xhr) {
            xhr.setRequestHeader('X-CSRF-Token', token)
        },
        data: data,
        success: function(response) {
            updateDataTable(table, response);
        },
        error: function(error) {
            console.log(error);
        }
    });
}

function updateDataTable(table, res) {
    for (i = 0; i < res['results'].length; ++i) {
        for (j = 0; j < table.rows().count(); j++) {
            let data = table.row(j).data();
            if (parseInt(data[0]) === parseInt(res['results'][i])) {
                table.row(j).remove().draw(true);
            }
        }
    }
}

$(document).on('click', '.items_selection .selection_menu_item', function () {
    $(this).closest('.selection_menu').find('.assign__selection').toggleClass('active');
    $(this).toggleClass('changed');
});

$(document).on('click', function(e) {
    let searchForm = $('#searchForm');
    let searchIconActive = $('.search_icon img.search_active');
    let searchIconInactive = $('.search_icon img.search_inactive');
    if ((!searchForm.is(e.target)) && (searchForm.has(e.target).length === 0) && (!searchIconActive.is(e.target)) && (searchIconActive.has(e.target).length === 0) && (!searchIconInactive.is(e.target)) && (searchIconInactive.has(e.target).length === 0)) {
        $('.search_wrapper').removeClass('collapsed');
    }
});

$(document).ready(function() {
    let authSubdomain = $('#auth_subdomains');
    let subdomain = authSubdomain.attr('value');
    authSubdomain.val(subdomain);
});

$(document).on('change', '#auth_subdomains', function() {
    let subdomain = $(this).val();
    if (subdomain === '') {
        window.location.href = 'https://' + subdomain + 'staging.nevvo.app/users/sign_in';
    } else {
        window.location.href = 'http://' + subdomain + '.nevvo.app/users/sign_in';
    }
});

function validationInput(input) {
    let submitBtn = $('.auth_content_wrapper .auth_content__wrapper form input[type="submit"]');

    if ((input.closest('form').find('input[type="password"]').val() !== '') && (input.closest('form').find('input[type="email"]').val() !== '')) {
        submitBtn.addClass('active');
        submitBtn.attr('disabled', false);
    } else {
        submitBtn.removeClass('active');
        submitBtn.attr('disabled', true);
    }
}

$(document).on('keyup', '.auth_content_wrapper .auth_content__wrapper form input[type="email"]', function() {
    validationInput($(this));
});

$(document).on('keyup', '.auth_content_wrapper .auth_content__wrapper form input[type="password"]', function() {
    validationInput($(this));
});

$(document).on('click', function(e) {
    let searchForm = $('#searchForm');
    let searchIconActive = $('.search_icon img.search_active');
    let searchIconInactive = $('.search_icon img.search_inactive');
    if ((!searchForm.is(e.target)) && (searchForm.has(e.target).length === 0) && (!searchIconActive.is(e.target)) && (searchIconActive.has(e.target).length === 0) && (!searchIconInactive.is(e.target)) && (searchIconInactive.has(e.target).length === 0)) {
        $('.search_wrapper').removeClass('collapsed');
    }
});

$(document).on('click', '.icon_group .icon_image', function() {
    $('.icon_group .icon_image').each(function() {
        $(this).removeClass('active');
    });
    $(this).addClass('active');
    let path = $(this).attr('path');
    $(this).closest('.form_field').find('.position_icon').val(path);
});

$(document).on('click', '.history_back', function() {
    history.go(-1);
});

$(document).on('click', '.custom-select-toggle', function() {
    $(this).closest('.select_tag').find('.select-custom').toggleClass('opened')
    $(this).toggleClass('changed')
});

$(document).on('click', '.select-custom .option-custom', function() {
    let filterValue = $(this).attr('value');
    let filterType = 'id';
    let filterElem = 'documents';
    let filterKey = $(this).find('a').attr('value');
    let element = $(this).closest('.page_content__wrapper').find('.contents_lists');
    let data = {
        f_type: filterType,
        f_val: filterValue,
        f_key: filterKey
    };
    filter(filterElem, data, element);
    $(this).closest('.select_tag').find('.select-custom').removeClass('opened');
    $(this).closest('.select_tag').find('.custom-select-toggle span').text($(this).text());
});

function filter(filterElem, data, element) {
    let token = $('meta[name="csrf-token"]').attr('content');
    $.ajax({
        url: '/' + filterElem + '/filter',
        type: 'post',
        dataType: "script",
        beforeSend: function(xhr) {
            xhr.setRequestHeader('X-CSRF-Token', token)
        },
        data: data,
        success: function(response) {
            $('.documents .document_lists').hide()
        },
        error: function(error) {
            console.log(error);
        }
    })
}

$(document).on('click', function(e) {
    let selectTag = $('.select_tag');
    let selectIcon = $('.custom-select-toggle');
    let selectOptions = $('.select-custom');
    if ((!selectTag.is(e.target)) && (selectTag.has(e.target).length === 0) && (!selectIcon.is(e.target)) && (selectIcon.has(e.target).length === 0) && (!selectOptions.is(e.target)) && (selectOptions.has(e.target).length === 0)) {
        selectOptions.removeClass('opened');
        selectIcon.removeClass('changed');
    }
});

$(document).on('click', '.category_icons', function() {
    let filterValue = $(this).attr('filter-value');
    $(this).closest('.post_model_items').find('.category_wrapper').each(function() {
        if ($(this).attr('filter-option') === filterValue) {
            if (!$(this).hasClass('open')) {
                $(this).addClass('open');
            }
        } else {
            $(this).removeClass('open');
        }
    });
    $(this).closest('.post_model_items').find('.category_icons').each(function () {
        $(this).removeClass('active');
    });
    $(this).addClass('active');

    if (filterValue == 'history') {
        $(this).parent().next().removeClass('d-flex').addClass('d-none')
    } else {
        $(this).parent().next().removeClass('d-none').addClass('d-flex')
    }
});

$(document).on('click', '.association_check', function(){
    let associationIDs = [];
    let associationName = $(this).attr('association_name');
    let associationType = $(this).closest('.post_model_item').find('.association_type').attr('value');
    let associationElement = $(this).closest('.post_model_item').find('.association_elem').attr('value');
    if (associationType == "teams") {
        $('.category_content_wrapper').find('.post_model_item').each(function() {
            if ($(this).find('.association_check').prop('checked') === true) {
                associationIDs.push(parseInt($(this).find('.association_id').attr('value')));
            }
            if ($(this).find('.team_association_check').prop('checked') === true) {
                associationIDs.push(parseInt($(this).find('.association_id').attr('value')));
            }
        });
    } else {
        $(this).closest('.category_content_wrapper').find('.post_model_item').each(function() {
            if ($(this).find('.association_check').prop('checked') === true) {
                associationIDs.push(parseInt($(this).find('.association_id').attr('value')));
            }
        });
    }
    
    let assertionMethod = $(this).prop('checked');
    let data = {
        a_type: associationType,
        a_elem: associationElement,
        provider: associationName,
        ids: associationIDs,
        a_method: assertionMethod
    };
    if (data["a_type"] == "teams" && $(this).hasClass('super_checkbox')) {
        data["a_role"] = "super"
    }
    
    manageAssociation(data, $(this), assertionMethod);
});

$(document).on('click', '.team_association_check', function(){
    let associationIDs = [];

    let selected_employee = $(this).parent().parent().find(".association_id").val()
    $("#changeTeamModal .selected_employee").text(selected_employee);

    let associationName = $(this).attr('association_name');
    $("#changeTeamModal .associationName").text(associationName);
    

    let associationType = $(this).closest('.post_model_item').find('.association_type').attr('value');
    $("#changeTeamModal .associationType").text(associationType);

    let associationElement = $(this).closest('.post_model_item').find('.association_elem').attr('value');
    $("#changeTeamModal .associationElement").text(associationElement);
    
    if (associationType == "teams") {
        $('.category_content_wrapper').find('.post_model_item').each(function() {
            if ($(this).find('.team_association_check').prop('checked') === true) {
                associationIDs.push(parseInt($(this).find('.association_id').attr('value')));
            }
            if ($(this).find('.association_check').prop('checked') === true) {
                associationIDs.push(parseInt($(this).find('.association_id').attr('value')));
            }
            // super_id = $(this).find('.association_check').parent().parent().find('.association_id').attr('value')
            // if (super_id != undefined) {
            //     associationIDs.push(parseInt(super_id));
            // }
        });
    }
    
    $("#changeTeamModal .associationIDs").text(associationIDs);

    let assertionMethod = $(this).prop('checked');
    $("#changeTeamModal .assertionMethod").text(assertionMethod);

    let super_checkbox = $(this).hasClass("super_checkbox");
    $("#changeTeamModal .super_checkbox").text(super_checkbox);

    let employeeName = $(this).closest('.post_model_item').find('.employee_name').attr('value');
    $("#changeTeamModal .employeeName").text(employeeName);

    let prevTeam = $(this).closest('.post_model_item').find('.prev_team').attr('value');
    $("#changeTeamModal .prevTeam").text(prevTeam);
    if (prevTeam == undefined) {
        $("#changeTeamModal .prevTeam").parent().hide();
    }
    
    $("#changeTeamModal").modal();
});

$(document).on('click', '#changeTeamModal .cancel, #changeTeamModal .close', function(){
    user_id = $("#changeTeamModal .selected_employee").text();
    selected_user = '#team_user_ids_' + user_id
    if($(selected_user).prop('checked')) {
        $(selected_user).prop('checked', false);
    } else {
        $(selected_user).prop('checked', true);
    }

    $("#changeTeamModal").modal("toggle");
});

$(document).on('click', '#changeTeamModal .submit', function(){
    associationName = $("#changeTeamModal .associationName").text();
    associationType = $("#changeTeamModal .associationType").text();
    associationElement = $("#changeTeamModal .associationElement").text();
    associationIDs = $("#changeTeamModal .associationIDs").text();
    assertionMethod = $("#changeTeamModal .assertionMethod").text();
    associationIDs = associationIDs.split(",")
    super_ids = $(".super_checkbox").attr("team_user_ids_")
    super_checkbox = $("#changeTeamModal .super_checkbox").text();

    let data = {
        a_type: associationType,
        a_elem: associationElement,
        provider: associationName,
        ids: associationIDs,
        a_method: assertionMethod
    };
    if (data["a_type"] == "teams" && super_checkbox == "true") {
        data["a_role"] = "super"
    }
    user_id = $("#changeTeamModal .selected_employee").text();
    selected_user = '#team_user_ids_' + user_id
    
    manageAssociation(data, $(selected_user), assertionMethod);
});

function manageAssociation(data, elem) {
    let token = $('meta[name="csrf-token"]').attr('content');
    $.ajax({
        url: '/' + data.a_type + '/associations',
        type: 'post',
        dataType: "json",
        beforeSend: function(xhr) {
            xhr.setRequestHeader('X-CSRF-Token', token)
        },
        data: data,
        success: function(response) {
            console.log(response);
            if (response['results']['status'] === 'ok') {
                if (response['results']['method'] === 'true') {
                    data
                    elem.prop('checked', true);
                } else {
                    elem.prop('checked', false);
                }
            } else {
                if (response['results']['method'] === 'true') {
                    elem.prop('checked', false);
                } else {
                    elem.prop('checked', true);
                }
            }
            if (data["a_type"] == "documents" && data["provider"] == "positions") {
                assign_data = {
                    ids: data["ids"],
                    document_id: data["a_elem"]
                }
                $.ajax({
                    url: '/documents/employees_per_position',
                    type: "post",
                    dataType: "json",
                    beforeSend: function(xhr) {
                        xhr.setRequestHeader('X-CSRF-Token', token)
                    },
                    data: assign_data,
                    success: function(response) {
                        employee_ids = response["results"]["ids"]
                        $(".employee-section input[type='checkbox']").prop('checked', false)
                        if (elem.closest('.post_model_items').find('.post_model_header .category_title span')) {
                            elem.closest('.post_model_items').find('.post_model_header .category_title span').text('(' + employee_ids.length + ')');
                        }
                        for (employee_id of employee_ids) {
                            $('[data-employee-id="'+employee_id+'"]').prop('checked', true)
                        }
                    },
                    error: function(error) {
                        console.log(error)
                    }
                })
            }
            if (elem.closest('.post_model_items').find('.post_model_header .category_title span')) {
                elem.closest('.post_model_items').find('.post_model_header .category_title span').text('(' + response['results']['associations']['count'] + ')');
            }
            
            $("#changeTeamModal").modal("hide");
        },
        error: function(error) {
            console.log(error);
        }
    })
}
$(document).on('keyup', '#search', function() {
    let searchKey = $(this).val().toLowerCase();
    let searchType = 'title';
    let searchElem = $(this).closest('#searchForm').attr('value');
    let element = $(this).closest('.page_content__wrapper').find('.contents_lists');
    let data = {
        s_type: searchType,
        s_key: searchKey
    };
    search(searchElem, data, element);
});

function search(searchElem, data, element) {
    let token = $('meta[name="csrf-token"]').attr('content');
    $.ajax({
        url: '/' + searchElem + '/search',
        type: 'post',
        dataType: "json",
        beforeSend: function(xhr) {
            xhr.setRequestHeader('X-CSRF-Token', token)
        },
        data: data,
        success: function(response) {
            let results = response.results;
            element.empty();
            if (searchElem === 'trainings') {
                for (i = 0; i < results.length; ++i) {
                    element.append(
                        '<div class="col-md-4 p-3">\n' +
                        '<a href="/trainings/'+ results[i]['id'] +'">\n' +
                        '<div class="item">\n' +
                        '<div class="d-flex flex-column align-items-center">\n' +
                        '<div class="item_title">\n' +
                        '<h5>'+ results[i]['title'] +'</h5>\n' +
                        '</div>\n' +
                        '<div class="item_content">\n' +
                        '<div class="documents">\n' +
                        '<img class="item_icon" src="/assets/trash_inactive-1ceeacb32f3945fe72ba620a9626f5738727ee777cebf69361e03c7bfe4dd445.png" alt="">\n' +
                        '<span>'+ results[i]['documents'] +' Documents</span>\n' +
                        '</div>\n' +
                        '<div class="employees">\n' +
                        '<img class="item_icon" src="/assets/user_icon_inactive-b3b9dce56ccadc16083e44a3f4809eaeb4dcc12fc249d91504f24fa091f7eb3c.png" alt="">\n' +
                        '<span>'+ results[i]['employees'] +' Employees</span>\n' +
                        '</div>\n' +
                        '</div>\n' +
                        '<div class="progress_wrapper">\n' +
                        '<div class="progress_label_wrapper d-flex align-items-center justify-content-between">\n' +
                        '<p class="progress_label">Progress</p>\n' +
                        '<p class="progress_percent">16%</p>\n' +
                        '</div>\n' +
                        '<div class="progress">\n' +
                        '<div class="progress-bar error" style="width: 16%"></div>\n' +
                        '</div>\n' +
                        '</div>\n' +
                        '</div>\n' +
                        '</div>\n' +
                        '</a></div>'

                    );
                }
            } else if (searchElem === 'documents') {
                for (i = 0; i < results.length; ++i) {
                    element.append(
                        '<div class="col-lg-2 col-md-4 p-3">\n' +
                        '<div class="item">\n' +
                        '<div class="d-flex flex-column align-items-center">\n' +
                        '<div class="item_title">\n' +
                        '<h5>'+ results[i]['title'] +'</h5>\n' +
                        '</div>\n' +
                        '<div class="progress_wrapper">\n' +
                        '<div class="progress_label_wrapper d-flex align-items-center justify-content-between">\n' +
                        '<p class="progress_label">Progress</p>\n' +
                        '<p class="progress_percent">16%</p>\n' +
                        '</div>\n' +
                        '<div class="progress">\n' +
                        '<div class="progress-bar error" style="width: 16%"></div>\n' +
                        '</div>\n' +
                        '</div>\n' +
                        '<a class="check_link" href="/documents/1">\n' +
                        '<span>Check</span>\n' +
                        '<img src="/assets/svgs/arrow_grey-028ecac33f51a9c2deeedc5f1df0b98b613a56e1e68a21135c7c7e099f17aeb0.svg" alt="">\n' +
                        '</a></div>\n' +
                        '</div>\n' +
                        '</div>'
                    );
                }
            } else if (searchElem === 'positions') {
                for (i = 0; i < results.length; ++i) {
                    element.append(
                        '<div class="col-md-3 p-3">\n' +
                        '<a class="item_polimero" href="/positions/'+ results[i]['id'] +'">\n' +
                        '<div class="d-flex align-items-center justify-content-between">\n' +
                        '<div class="d-flex align-items-center">\n' +
                        '<img class="polimero_active_icon" src="/assets/search_icons/'+ results[i]['icon'] +'" alt="">\n' +
                        '<h4 class="polimero_title">' +results[i]['name']+ '</h4>\n' +
                        '</div>\n' +
                        '</div>\n' +
                        '</a></div>'
                    );
                }
            } else if (searchElem === 'teams') {
                for (i = 0; i < results.length; ++i) {
                    element.append(
                        '<div class="col-md-3 p-3">\n' +
                        '<a class="item_polimero" href="/teams/'+ results[i]['id'] +'">\n' +
                        '<div class="d-flex align-items-center justify-content-between">\n' +
                        '<div class="d-flex align-items-center">\n' +
                        '<img class="polimero_active_icon" src="/assets/search_icons/'+ results[i]['icon'] +'" alt="">\n' +
                        '<h4 class="polimero_title">' +results[i]['name']+ '</h4>\n' +
                        '</div>\n' +
                        '</div>\n' +
                        '</a></div>'
                    );
                }
            }
        },
        error: function(error) {
            console.log(error);
        }
    })
}

function readURL(input, element) {
    if (input.files && input.files[0]) {
        if (input.files[0].size <= 50000000){
            $('.upload_message.failed').removeClass('show');
            var reader = new FileReader();
            reader.onload = function(e) {
                element.closest(".user_image").find('.image_preview').attr('src', e.target.result);
                element.closest(".user_image").find('.image_preview_url').text(input.files[0].name);
            };
            reader.readAsDataURL(input.files[0]);
        } else {
            $('.upload_message.failed').addClass('show');
            setTimeout(function(){ $('.upload_message.failed').removeClass('show');}, 3000);
        }

    }
}
$(document).on("click", ".user_avatar", function() {
    if ($(this).attr('type') === 'text') {
        $(this).attr('type', 'file');
        $(this).val('');
    }
    $(this).change();
});
$(document).on("change", ".user_avatar", function() {
    if ($(this).closest('.user_image').find('.user_avatar_section img.image_preview').length <= 0) {
        $(this).closest('.user_image').find('.user_avatar_section .user_avatar_no_image.user_avatar_image').remove();
        $(this).closest('.user_image').find('.user_avatar_section').prepend('<img src="/assets/image_preview.jpg" class="image_preview user_avatar_preview" alt="user">');
    }
    readURL(this, $(this));
});

$(document).on('click', '.btn_del_user_avatar', function(e) {
    e.preventDefault();
    $(this).closest(".user_image").find('.image_preview_url').text('');
    $(this).closest(".user_image").find('.image_preview').attr('src', '/assets/image_preview.jpg');
    $(this).closest(".user_image").find('.user_avatar').attr('type', 'text');
    $(this).closest(".user_image").find('.user_avatar').val('empty');
    
    user_id = $(this).data("user")
    let token = $('meta[name="csrf-token"]').attr('content');
    $.ajax({
        url: '/home/delete_avatar',
        type: 'post',
        dataType: "json",
        beforeSend: function(xhr) {
            xhr.setRequestHeader('X-CSRF-Token', token)
        },
        data: {
            id: user_id
        },
        success: function(response) {
            console.log(response);
        },
        error: function(error) {
            console.log(error);
        }
    })
});

$(document).on('click', '.resend_invite', function() {
    let id = $(this).attr('value');
    var wrapper = $(this);
    let token = $('meta[name="csrf-token"]').attr('content');
    $.ajax({
        url: '/employees/resend',
        type: 'get',
        dataType: "json",
        beforeSend: function(xhr) {
            xhr.setRequestHeader('X-CSRF-Token', token)
        },
        data: {
            id: id
        },
        success: function(response) {
            $('.required-password').hide();
            $('.success_invited').show();
            console.log(response);
        },
        error: function(error) {
            console.log(error);
        }
    })
});

$(document).on('click', '.association_create', function() {
    let assoc_ids = [];
    $(this).closest('.assoc_wrapper').find('.association_create').each(function(){
        if ($(this).prop('checked') === true) {
            assoc_ids.push(parseInt($(this).attr('assoc_id')));
        }
    });
    $(this).closest('.assoc_wrapper').find('.association_ids').val(assoc_ids);
});
$(document).on('click', '.password_enable', function() {
    $(this).closest('.password_section').find('.password_input').prop('disabled', false);
    $(this).closest('.password_section').find('.password_input').attr('value', '');
    $(this).closest('.password_label').find('label.label').text($(".current_password_text").text());
    $(this).closest('.password_section').addClass("active");
    $(this).addClass('deactive');
});
$(document).on('click', '.notification_check', function() {
    let dataId = $(this).attr('data-id');
    let dataValue = $(this).prop('checked');
    var data = {
        data_id: dataId,
        value: dataValue
    };
    notifications(data, $(this), dataValue);
});

function notifications(data, elem, value) {
    let token = $('meta[name="csrf-token"]').attr('content');
    $.ajax({
        url: '/settings/notifications',
        type: 'post',
        dataType: "json",
        beforeSend: function(xhr) {
            xhr.setRequestHeader('X-CSRF-Token', token)
        },
        data: data,
        success: function(response) {
            if (response === true) {
                elem.prop('checked', true);
            } else if (response === false) {
                elem.prop('checked', false);
            } else {
                if (value === true) {
                    elem.prop('checked', false);
                } else {
                    elem.prop('checked', true);
                }
            }
        },
        error: function(error) {
            console.log(error);
        }
    })
}
$(document).on('click', '.language_wrapper', function() {
    $(this).closest('.language_tab').find('.language_options').toggleClass('showed');
    $(this).toggleClass('active');
});
$(document).on('click', function(e) {
    let toggleIcon = $('.language_wrapper');
    let language = $('.language_options');
    if ((!toggleIcon.is(e.target)) && (toggleIcon.has(e.target).length === 0) && (!language.is(e.target)) && (language.has(e.target).length === 0)) {
        language.removeClass('showed');
        toggleIcon.removeClass('active');
    }
});

$(document).on('click', '.language_item', function() {
    $(this).closest('.language_tab').find('.language_options').removeClass('showed');
    $(this).closest('.language_tab').find('.language_toggle_icon').removeClass('active');
    let data = {
        lang: $(this).find('.language_icon span').text().toLowerCase()
    };

    updateLang(data, $(this));
});

function updateLang(data, elem) {
    let token = $('meta[name="csrf-token"]').attr('content');
    $.ajax({
        url: '/settings/lang',
        type: 'post',
        dataType: "json",
        beforeSend: function (xhr) {
            xhr.setRequestHeader('X-CSRF-Token', token)
        },
        data: data,
        success: function (response) {
            if (response['results'] === data['lang']) {
                elem.closest('.language_tab').find('.language_wrapper p').text(elem.find('p').text());
                elem.closest('.language_tab').find('.language_wrapper span').text(elem.find('.language_icon span').text());
                window.location.reload();
            }
        },
        error: function (error) {
            console.log(error);
        }
    });
}

$(document).on('click', '.filter_button', function() {
    $(this).closest('.filter_btn_wrapper').find('.filter_button').each(function() {
        $(this).removeClass('active');
    });
    $(this).addClass('active');
});
$(document).on('click', function(e) {
    let menu = $('.assign__selection');
    let toggler = $('.selection_menu_item');
    if ((!menu.is(e.target)) && (menu.has(e.target).length === 0) && (!toggler.is(e.target)) && (toggler.has(e.target).length === 0)) {
        toggler.removeClass('changed');
        menu.removeClass('active');
    }
});

function deleteAssoc(data, wrapper) {
    let token = $('meta[name="csrf-token"]').attr('content');
    $.ajax({
        url: '/' + data.a_type + '/delassoc',
        type: 'post',
        dataType: "json",
        beforeSend: function (xhr) {
            xhr.setRequestHeader('X-CSRF-Token', token)
        },
        data: data,
        success: function (response) {
            if (response['results']['assoc']['mes'] === 'success') {
                wrapper.remove();
            }
        },
        error: function (error) {
            console.log(error);
        }
    });
}

$(document).on('click', '.delete_assoc', function(e) {
    e.preventDefault();
    let assocName = $(this).attr('assoc_name');
    let assocType = $(this).closest('.post_model_item').find('.association_type').attr('value');
    let assocElem = $(this).closest('.post_model_item').find('.association_elem').attr('value');
    let assocID = $(this).closest('.post_model_item').find('.association_id').attr('value');
    let data = {
        a_type: assocType,
        a_elem: assocElem,
        provider: assocName,
        id: assocID
    };
    deleteAssoc(data, $(this).closest('.post_model_item'));
});

$(document).on('keyup', '.assoc_search', function() {
    let searchValue = $(this).val().toLowerCase();
    $(this).closest('.post_model_items').find('.post_model_item').each(function() {
        if ($(this).attr('filterValue') != undefined) {
            if ($(this).attr('filterValue').includes(searchValue)) {
                $(this).removeClass('hidden');
            } else {
                $(this).addClass('hidden');
            }
        }
    });
});

$(document).on('click', '.document_media_type .radio', function() {
    if ($("#document_media_video").is(":checked")) {
        $(this).closest('.document_select_form').find('.document_type_field.file_link').addClass('active');
        $(this).closest('.document_select_form').find('.document_type_field.file_upload').removeClass('active');
        $(this).closest('.document_select_form').find('.document_type_field.file_link .link_input').prop('required', true);
        // $(this).closest('.document_select_form').find('.document_type_field.file_upload .custom_file_input').prop('required', false);
    } else if ($("#document_media_file").is(":checked")) {
        $(this).closest('.document_select_form').find('.document_type_field.file_upload').addClass('active');
        $(this).closest('.document_select_form').find('.document_type_field.file_link').removeClass('active');
        $(this).closest('.document_select_form').find('.document_type_field.file_link .link_input').prop('required', false);
        // $(this).closest('.document_select_form').find('.document_type_field.file_upload .custom_file_input').prop('required', true);
    } else {
        $(this).closest('.document_select_form').find('.document_type_field.file_upload').removeClass('active');
        $(this).closest('.document_select_form').find('.document_type_field.file_link').removeClass('active');
        $(this).closest('.document_select_form').find('.document_type_field.file_link .link_input').prop('required', false);
    }
});

$(document).on('change', '.document_upload', function () {
    let data = {
        id: $(this).closest('.file_upload').find('.custom_file').attr('data-id'),
        data: $(this).val()
    };
    checkSize(this, $(this));
    uploadDoc(data, $(this));
});

function uploadDoc(data, elem) {
    let token = $('meta[name="csrf-token"]').attr('content');
    $.ajax({
        url: '/document/upload',
        type: 'post',
        dataType: "json",
        beforeSend: function (xhr) {
            xhr.setRequestHeader('X-CSRF-Token', token)
        },
        data: data,
        success: function (response) {
            console.log(response);
        },
        error: function (error) {
            console.log(error);
        }
    });
}
$(document).on('click', '.notification_enable_check', function() {
    if ($(this).prop('checked') === true) {
        $(this).closest('.page_content__wrapper').find('.option_content_overlay').removeClass('active');
        $(this).closest('.page_content__wrapper').find('.notification_check.sub_notification').prop('disabled', false);
    } else {
        $(this).closest('.page_content__wrapper').find('.option_content_overlay').addClass('active');
        $(this).closest('.page_content__wrapper').find('.notification_check.sub_notification').prop('disabled', true);
    }
});


function checkSize(input, element) {
    if (input.files && input.files[0]) {
        if (input.files[0].size <= 25000000){
            $('.upload_message.failed').removeClass('show');
            element.closest('.custom_file').find('.message').removeClass('error');
            element.closest('.custom_file').find('.message').addClass('success');
            element.closest('.custom_file').find('.message .check_text').text($(".file_uploaded_text").text());
        } else {
            element.closest('.custom_file').find('.message').removeClass('success');
            element.closest('.custom_file').find('.message').addClass('error');
            element.closest('.custom_file').find('.message .check_text').text('upload error');
            $('.upload_message.failed').addClass('show');
            element.val('');
            setTimeout(function(){ $('.upload_message.failed').removeClass('show');}, 3000);
        }
    }
}
$(document).on('click', '.required_check_section .custom_checkbox input[type=checkbox]', function(){
    if ($(this).prop('checked') === true) {
        $(this).closest('.required_check_section').find('.deadline_field').removeClass('hidden');
    } else {
        $(this).closest('.required_check_section').find('.deadline_field').addClass('hidden');
    }
});

$(document).on('click', 'a.confirm-document', function() {
    $('#docCompleteConfirmModal').modal('show');
})

$(document).on('click', "#missed_elements_table tr", function() {
    url = $(this).data('src')
    if (url)
        window.location = url
})

$( document ).on('turbolinks:load', function() {
    if ($('.office_status').text() == "true") {
        $('.azure-apps').show();
    } else {
        $('.azure-apps').hide();
    }
})

$(document).on('click', '#organization_office_status', function() {
    if ($('#organization_office_status').is(':checked')) {
        $('.azure-apps').show();
    } else {
        $('.azure-apps').hide();
    }
})

$(document).on('click', '.employee-collapse', function() {
    if ($(this).hasClass("collapsed")) {
        $(this).find(".collapse-close").hide();
        $(this).find(".open").show();
    } else {
        $(this).find(".open").hide();
        $(this).find(".collapse-close").show();
    }
})

$(document).on('click', '#super_table .team-validation .approve', function() {
    ud_id = $(this).data("id");
    validated_text = $(".validated-text").text();
    selected_row = $(this)
    let token = $('meta[name="csrf-token"]').attr('content');
    $.ajax({
        url: '/complete/validation',
        type: 'post',
        dataType: "json",
        beforeSend: function(xhr) {
            xhr.setRequestHeader('X-CSRF-Token', token)
        },
        data: {
            ud_id: ud_id
        },
        success: function(response) {
            selected_row.closest("td").text(validated_text)
            console.log("success");
        },
        error: function(error) {
            console.log(error);
        }
    });
});

$(document).on('click', '#super_table .delegate-validation', function() {
    ud_id = $(this).next().text();
    validated_text = $(".validated-text").text();
    selected_row = $(this)
    let token = $('meta[name="csrf-token"]').attr('content');
    $.ajax({
        url: '/complete/validation',
        type: 'post',
        dataType: "json",
        beforeSend: function(xhr) {
            xhr.setRequestHeader('X-CSRF-Token', token)
        },
        data: {
            ud_id: ud_id
        },
        success: function(response) {
            selected_row.closest("td").text(validated_text)
            console.log("success");
        },
        error: function(error) {
            console.log(error);
        }
    });
});


$(document).on('click', '#super_table .team-validation .delegate', function(e) {
    e.preventDefault();
    validation_status = $(this).data("validation-status")

    if ($(this).data("period") > 0) {
        ud_ids = []
        $('#delegateModal #data-period').val($(this).data('period'));
        user_ids = $($('.team-validation').find(`[data-userid='${$(this).data('userid')}']`))
        for(i=0; i < user_ids.length; i++) {
            ud_ids.push($(user_ids[i]).prev().data('id'))
        }
        $('#delegateModal #docUserId').val($(this).data('userid'));
        $('#delegateModal #udId').val(ud_ids.toString());
    } else {
        $('#delegateModal #udId').val($(this).data('id'));
    }
    
    let token = $('meta[name="csrf-token"]').attr('content');
    let data = {
        validation_status: validation_status
    };

    $.ajax({
        url: '/teams/delegate_users',
        type: 'post',
        dataType: "script",
        beforeSend: function(xhr) {
            xhr.setRequestHeader('X-CSRF-Token', token)
        },
        data: data,
        success: function(response) {
            $("#delegateModal").modal("show")
        },
        error: function(xhr, error, thrownError) {
            console.log('fail  status:[' + xhr.status + ']  error:[' + thrownError + ']');
            console.log(error);
        }
    });
});

$(document).on('click', '.delegate_check', function(){
    if ($('input[type=checkbox]:checked').length > 0) {
        $('input[type=checkbox]:checked').prop("checked", false);
        $(this).prop("checked", true)
        $("#delegateModal .modal_footer .link_submit").css({'cursor': 'pointer'})
        $("#delegateModal .modal_footer .link_submit").removeClass("disabled")
    } else {
        $("#delegateModal .modal_footer .link_submit").addClass("disabled")
    }
});

$(document).on('click', '#delegateModal .modal_footer .link_submit', function(){
    var checked_list = $('input[type=checkbox]:checked')
    user_ids = []
    for(i=0; i<checked_list.length; i++) {
        u_id = $(checked_list[i]).parent().next().val();
        user_ids.push(u_id)
    }
    ud_id = $("#delegateModal #udId").val();
    data_period = $("#delegateModal #data-period").val();
    docUserId = $("#delegateModal #docUserId").val();
    let data = {
        employee_ids: user_ids,
        ud_id: ud_id,
        data_period: data_period,
        docUserId: docUserId
    };

    let token = $('meta[name="csrf-token"]').attr('content');
    $.ajax({
        url: '/assign/delegate',
        type: 'post',
        dataType: "json",
        beforeSend: function(xhr) {
            xhr.setRequestHeader('X-CSRF-Token', token)
        },
        data: data,
        success: function(response) {
            ud_id_list = ud_id.split(',')
            for(i=0; i < ud_id_list.length; i++) {
                // $('.team-validation').find(`[data-id='${ud_id_list[i]}']`).closest("tr").remove();
                waiting_validation_text = $(".waiting-validation").text() + ' ' + response.username
                $('.team-validation').find(`[data-id='${ud_id_list[i]}']`).closest("tr").find("a.delegate").closest("td").text(waiting_validation_text)
            }
            $('#delegateModal').modal("toggle")
            $('input[type=checkbox]:checked').prop("checked", false);
        },
        error: function(error) {
            console.log(error);
        }
    })
});

$(document).on('click', '.super_checkbox', function(){
    if ($(this).prop("checked")) {
        $(".super_checkbox").prop("checked", false);
        $(this).prop("checked", true);
    }
})

$(document).on('click', '.create_question_list', function(e){
    e.preventDefault();
    e.stopPropagation();
    $("#questionModal .question-types").val(0)
    $("#questionModal .question-text").val("")
    $("#questionModal .question-text").next().hide();
    $("#questionModal .multi-fields").next().hide();
    var answerLength = $(".answer-inputbox").length
    i = 1
    while (answerLength > 1) {
        $($("#questionModal .answer-inputbox")[answerLength - i]).remove();
        answerLength--;
    }
    
    $("#questionModal .answer-content").val("")
    $("#questionModal .answer-content").next().find('.active-hand-down').removeClass("incorrect")
    $("#questionModal .answer-content").next().find('.active-hand-down').addClass("d-none");
    $("#questionModal .answer-content").next().find('.inactive-hand-down').removeClass("d-none")
    $("#questionModal .answer-content").next().find('.active-hand-up').removeClass("correct")
    $("#questionModal .answer-content").next().find('.active-hand-up').addClass("d-none");
    $("#questionModal .answer-content").next().find('.inactive-hand-up').removeClass("d-none");

    $("#questionModal").modal("show");
})

$(document).on('click', '.create-questions', function(e){
    e.preventDefault();
    e.stopPropagation();

    questionType = $("#questionModal .question-types").val()
    question = $("#questionModal .question-text").val()
    answers = []
    correct_answers = []
    incorrect_answers = []
    incorrect_idx = 0
    correct_idx = 0
    $("#questionModal .answer-content").each(function(index) {
        if ($(this).val().length > 0 ) {
            answers.push($(this).val())
        }

        if ($(this).next().find(".correct").length > 0) {
            if (answers.indexOf($(this).val()) >= 0) {
                correct_answers.push(index + incorrect_idx)
            } else {
                incorrect_idx = answers.indexOf($(this).val())
            }
        }
        if ($(this).next().find(".incorrect").length > 0) {
            if (answers.indexOf($(this).val()) >= 0) {
                incorrect_answers.push(index + correct_idx)
            } else {
                correct_idx = answers.indexOf($(this).val())
            }
        }
    })

    quizId = $(".quiz-id").text()

    data = {
        questionType: questionType,
        question: question,
        answers: answers,
        quizId: quizId,
        correct_answers: correct_answers,
        incorrect_answers: incorrect_answers
    }

    let token = $('meta[name="csrf-token"]').attr('content');
    
    is_valiated = true
    if (question.length == 0) {
        is_valiated = false
        $("#questionModal .question-text").next().show();
    } else if (answers.length == 0) {
        is_valiated = false
        $("#questionModal .multi-fields").next().show();
    }

    if (is_valiated) {
        save_questions(data, token, "#questionModal")
    }
})

$(document).on('click', '.edit-questions', function(e){
    e.preventDefault();
    e.stopPropagation();
    
    questionId = $(this).attr("data-question")
    questionType = $(this).closest("#editquestionModal").find(".question-types").val()
    question = $(this).closest("#editquestionModal").find(".question-text").val()
    
    answers = []
    correct_answers = []
    incorrect_answers = []
    correct_idx = 0
    incorrect_idx = 0
    $(this).closest("#editquestionModal").find(".answer-content").each(function(index) {
        if ($(this).val()) {
            answers.push($(this).val())
        }
        if ($(this).next().find(".correct").length > 0) {
            if (answers.indexOf($(this).val()) >= 0) {
                correct_answers.push(index + incorrect_idx)
            } else {
                incorrect_idx = answers.indexOf($(this).val())
            }
        }
        if ($(this).next().find(".incorrect").length > 0) {
            if (answers.indexOf($(this).val()) >= 0) {
                incorrect_answers.push(index + correct_idx)
            } else {
                correct_idx = answers.indexOf($(this).val())
            }
        }
    })
    
    quizId = $(".quiz-id").text()
    data = {
        questionType: questionType,
        question: question,
        answers: answers,
        quizId: quizId,
        questionId: questionId,
        correct_answers: correct_answers,
        incorrect_answers: incorrect_answers
    }

    let token = $('meta[name="csrf-token"]').attr('content');

    is_valiated = true
    if (question.length == 0) {
        is_valiated = false
        $(this).closest("#editquestionModal").find(".question-text").next().show();
    } else if (answers.length == 0) {
        is_valiated = false
        $(this).closest("#editquestionModal").find(".edit-multi-fields").next().show();
    }

    if (is_valiated) {
        save_questions(data, token, "#editquestionModal")
    }
})

function save_questions(data, token, modalId) {
    $.ajax({
        url: '/upsert/question',
        type: 'post',
        dataType: "script",
        beforeSend: function(xhr) {
            xhr.setRequestHeader('X-CSRF-Token', token)
        },
        data: data,
        success: function(response) {
            $(modalId).modal("toggle")
            $(".exising-question-list").remove();
            $('.modal-backdrop').remove();
            console.log("success")
        },
        error: function(error) {
           console.log(error);
        }
    })
}

$(document).on('click', '.question_delete', function(e){
    e.preventDefault();
    e.stopPropagation();
    quizId = $(this).attr('data-quiz')
    questionId = $(this).attr('data-question')

    data = {
        questionId: questionId,
        quizId: quizId
    }

    let token = $('meta[name="csrf-token"]').attr('content');
    $.ajax({
        url: '/remove/question',
        type: 'post',
        dataType: "script",
        beforeSend: function(xhr) {
            xhr.setRequestHeader('X-CSRF-Token', token)
        },
        data: data,
        success: function(response) {
            $('#deleteModal_' + questionId).modal("hide");
            $('#questiondeleteModal_' + questionId).modal("hide");
            $(".exising-question-list").hide();
            $('.modal-backdrop').remove();
            console.log("success")
        },
        error: function(error) {
           console.log(error);
        }
    })
})

$(document).on('click', '#editquestionscontentModal', function(e){
    e.preventDefault();
    e.stopPropagation();
    questionId = $(this).attr("data-question")
    data = {
        questionId: questionId,
    }

    let token = $('meta[name="csrf-token"]').attr('content');
    $.ajax({
        url: '/edit/question',
        type: 'post',
        dataType: "script",
        beforeSend: function(xhr) {
            xhr.setRequestHeader('X-CSRF-Token', token)
        },
        data: data,
        success: function(response) {
            $('#editquestionModal').modal("show");
            // $('.modal-backdrop').remove();
            console.log("success")
        },
        error: function(error) {
           console.log(error);
        }
    })
})


$(document).on('click', '.inactive-hand-down', function(e){
    $(this).addClass("d-none");
    if ($(this).parent().find(".active-hand-down").hasClass("d-none")) {
        $(this).parent().find(".active-hand-down").removeClass("d-none")
        $(this).parent().find(".active-hand-down").addClass("incorrect")
    } else {
        $(this).prev().addClass("active-hand-down incorrect")
        $(this).prev().removeClass("d-none");
    }

    $(this).parent().find(".inactive-hand-up").removeClass("d-none");

    question_types = $(this).closest(".modal").find(".question-types").val();
    
    if (question_types == '1') {
        $this = $(this)
        $(".correct").each(function() {
            $(this).parent().find(".inactive-hand-up").removeClass("d-none");
            $(this).addClass("d-none");
            $(this).removeClass("correct");
        });
        $(".incorrect").each(function() {
            if ($("input").index($this.parent().prev()) != $("input").index($(this).parent().prev())){
                $(this).parent().find(".inactive-hand-down").removeClass("d-none")
                $(this).addClass("d-none");
                $(this).removeClass("incorrect");
            }
        })
    } else {
        $(this).parent().find(".inactive-hand-up").removeClass("d-none")
        $(this).parent().find(".correct").addClass("d-none")
        $(this).parent().find(".correct").removeClass("correct")
    }
});

$(document).on('click', '.inactive-hand-up', function(e){
    $(this).addClass("d-none");
    if ($(this).parent().find(".active-hand-up").hasClass("d-none")) {
        $(this).parent().find(".active-hand-up").removeClass("d-none")
        $(this).parent().find(".active-hand-up").addClass("correct")
        
    } else {
        $(this).prev().addClass("active-hand-up correct")
        $(this).prev().removeClass("d-none");

    }
    $(this).parent().find(".inactive-hand-down").removeClass("d-none")
    
    question_types = $(this).closest(".modal").find(".question-types").val();
    
    if (question_types == '1') {
        $this = $(this)
        $(".correct").each(function() {
            if ($("input").index($this.parent().prev()) != $("input").index($(this).parent().prev())){
                $(this).parent().find(".inactive-hand-up").removeClass("d-none");
                $(this).addClass("d-none");
                $(this).removeClass("correct");
            }
        });
        $(".incorrect").each(function() {
            $(this).parent().find(".inactive-hand-down").removeClass("d-none")
            $(this).addClass("d-none");
            $(this).removeClass("incorrect");
        })
    } else {
        $(this).parent().find(".incorrect").addClass("d-none")
        $(this).parent().find(".incorrect").removeClass("incorrect")
    }
});


$(document).on('click', '.incorrect', function(e){
    $(this).removeClass("incorrect")
    $(this).addClass("d-none");
    if ($(this).parent().find(".inactive-hand-down").hasClass("d-none")) {
        $(this).parent().find(".inactive-hand-down").removeClass("d-none")
    }
});

$(document).on('click', '.correct', function(e){
    $(this).removeClass("correct")
    $(this).addClass("d-none");
    if ($(this).parent().find(".inactive-hand-up").hasClass("d-none")) {
        $(this).parent().find(".inactive-hand-up").removeClass("d-none")
    }
});

$(document).on('click', '.quiz-confirm-btn', function(e){
    $(".modal-quiz-confirm").css({"display": "block"})
});

$(document).on('click', '.modal-quiz-confirm', function(e){
    e.preventDefault();
    e.stopPropagation();
    quizId = $(this).attr("id")
    let token = $('meta[name="csrf-token"]').attr('content');
    data = {
        quizId: quizId,
    }
    $.ajax({
        url: '/take/quiz',
        type: 'post',
        dataType: "script",
        beforeSend: function(xhr) {
            xhr.setRequestHeader('X-CSRF-Token', token)
        },
        data: data,
        success: function(response) {
            $('#takequestionModal').modal("show");
            console.log("success")
        },
        error: function(error) {
            console.log(error);
        }
    })
});

$(document).on('click', '.take-quiz-confirm', function(e){
    e.preventDefault();
    e.stopPropagation();
    $('#takequestionModal').modal("hide");
    quizId = $(this).attr("quiz_id")
    checked_items = $(this).closest(".take_quiz_modal_content").find(".question-modal-description").find('input[type=checkbox]:checked')
    answers = []
    checked_items.each(function(e) {
        question_id = $(this).closest(".question-modal-description").attr("id");
        answer_id = $(this).attr("id")
        answers.push(
            {
                "question_id": question_id, answer_id: answer_id
            }
        )
    })

    checked_radio_items = $(this).closest(".take_quiz_modal_content").find(".question-modal-description").find('input[type=radio]:checked')
    checked_radio_items.each(function(e) {
        question_id = $(this).closest(".question-modal-description").attr("id");
        answer_id = $(this).attr("id")
        answers.push(
            {
                "question_id": question_id, answer_id: answer_id
            }
        )
    })

    quiz_ud_id =$(".quiz_ud_id").text();
    data = {
        quizId: quizId,
        answers: answers,
        ud_id: quiz_ud_id
    }
    

    let token = $('meta[name="csrf-token"]').attr('content');

    $.ajax({
        url: '/result/quiz',
        type: 'post',
        dataType: "script",
        beforeSend: function(xhr) {
            xhr.setRequestHeader('X-CSRF-Token', token)
        },
        data: data,
        success: function(response) {
            if (response.indexOf('">ok<') > 0) {
                $(".quiz-confirm-btn").hide();
                $(".quiz-complete-btn").show();
            } else {
                $('#resultquestionModal').modal("show");
            }
            console.log("success")
        },
        error: function(error) {
            console.log(error);
        }
    })

})

$(document).on('click', '.result-quiz-confirm', function(e){
    e.preventDefault();
    e.stopPropagation();
    $('#resultquestionModal').modal("hide");
    quizId = $(this).attr("quiz_id")
    checked_items = $(this).closest(".result_quiz_modal_content").find(".question-modal-description").find('input[type=checkbox]:checked')
    answers = []
    checked_items.each(function(e) {
        question_id = $(this).closest(".question-modal-description").attr("id");
        answer_id = $(this).attr("id")
        answers.push(
            {
                "question_id": question_id, answer_id: answer_id
            }
        )
    })

    checked_radio_items = $(this).closest(".result_quiz_modal_content").find(".question-modal-description").find('input[type=radio]:checked')
    checked_radio_items.each(function(e) {
        question_id = $(this).closest(".question-modal-description").attr("id");
        answer_id = $(this).attr("id")
        answers.push(
            {
                "question_id": question_id, answer_id: answer_id
            }
        )
    })
    quiz_ud_id =$(".quiz_ud_id").text();
    data = {
        quizId: quizId,
        answers: answers,
        ud_id: quiz_ud_id
    }
    
    let token = $('meta[name="csrf-token"]').attr('content');

    $.ajax({
        url: '/result/quiz',
        type: 'post',
        dataType: "script",
        beforeSend: function(xhr) {
            xhr.setRequestHeader('X-CSRF-Token', token)
        },
        data: data,
        success: function(response) {
            setTimeout(function(){
                if (response.indexOf('">ok<') > 0) {
                    $(".quiz-confirm-btn").hide();
                    $(".quiz-complete-btn").show();
                } else {
                    $('#resultquestionModal').modal("show");
                }
                console.log("success")
            }, 300);
            
            
        },
        error: function(error) {
            console.log(error);
        }
    })
})

$(document).on('click', '.error_custom_check', function(e){
    if (!$(this).is(":checked")){
        $(this).removeClass("error_custom_check")
    }
})

$(document).on('click', '.error_custom_radio', function(e){
    if (!$(this).is(":checked")){
        $(this).removeClass("error_custom_radio")
    }
});

$(document).on('click', '.modal .custom_radiobox input[type=radio]', function(){
    $(this).closest(".edit-multi-fields").find("input").prop('checked', false);
    $(this).closest(".edit-multi-fields").find(".error_custom_radio").removeClass("error_custom_radio")
    if ($(this).prop("checked") === false) {
        $(this).prop('checked', true)
    }
});

$(document).on('click', '#resultquestionModal .error_remove_answer', function(){
    $(this).parent().prev().find("input").prop('checked', false);
    $(this).parent().prev().find(".error_custom_radio").removeClass("error_custom_radio");
    $(this).parent().prev().find(".error_custom_check").removeClass("error_custom_check");
    $(this).hide();
});


$(document).on('click', '.select-custom .quiz-option-custom', function() {
    let filterValue = $(this).attr('value');
    let filterType = 'id';
    let filterElem = 'quizzes';
    let filterKey = $(this).find('a').attr('value');
    let element = $(this).closest('.page_content__wrapper').find('.contents_lists');
    let data = {
        f_type: filterType,
        f_val: filterValue,
        f_key: filterKey
    };
    quiz_filter(filterElem, data, element);
    $(this).closest('.select_tag').find('.select-custom').removeClass('opened');
    $(this).closest('.select_tag').find('.custom-select-toggle span').text($(this).text());
});

function quiz_filter(filterElem, data, element) {
    let token = $('meta[name="csrf-token"]').attr('content');
    $.ajax({
        url: '/' + filterElem + '/filter',
        type: 'post',
        dataType: "script",
        beforeSend: function(xhr) {
            xhr.setRequestHeader('X-CSRF-Token', token)
        },
        data: data,
        success: function(response) {
            $('.quizzes .quiz_lists').hide()
        },
        error: function(error) {
            console.log(error);
        }
    })
}